import logo from './logo.svg';
import './App.css';
import {useEffect, useRef, useState} from "react";
import associateServices from "./services";
import StyledContent from "./styledContent";
import FX1 from '../src/assets/fxfutures/fxfutures1.png'
import FX2 from '../src/assets/fxfutures/fxfutures2.png'
import FX3 from '../src/assets/fxfutures/fxfutures3.png'

import CRYPTO1 from '../src/assets/cryptofutures/cryptofu1.png'
import CRYPTO2 from '../src/assets/cryptofutures/cryptofu2.png'
import CRYPTO3 from '../src/assets/cryptofutures/cryptofu3.png'


import BINARY1 from '../src/assets/binary/binary1.png'
import BINARY2 from '../src/assets/binary/binary2.png'
import BINARY3 from '../src/assets/binary/binary3.png'
import Big from "big.js";

function App() {

    const [token, setToken] = useState('')
    const fxRef = useRef(null)
    const cryptoRef = useRef(null)
    const binaryRef = useRef(null)
    const [stats, setStats] = useState([
        {
            key: 'crypto',
            tt: '#tab-02',
            class: 'game--green',
            title: 'CRYPTO FUTURES',
            desc1: 'Kripto Kaldıraç ile hızlı kazanın!',
            sub: "Kripto Kaldıraç",
        },


        {
            key: 'binary',
            tt: '#tab-01',
            class: 'game--blue',
            title: 'BINARY',
            desc1: 'Seçilen sürede düşecek mi? Yükselecek mi?',
            sub: "İkili Opsiyon",

        },

        {
            key: 'fx',
            tt: '#tab-03',
            class: 'game--orange',
            title: 'FX FUTURES',
            desc1: 'Çarpan etkisiyle çift yönlü işlem fırsatı!',
            sub: "Forex Kaldıraç",

        },
    ])

    function currencyFormat(num) {
        return '' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    useEffect(() => {
        associateServices.getStats({}).then(res => {
            setStats(prevState => prevState.map(stat => {
                const theStat = res?.data?.find(i => i.key === stat.key)
                return {
                    ...stat,
                    total: theStat.total,
                    count: theStat.count,
                }
            }))
        })
    }, [])


    function gup(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        let regexS = "[\\?&]" + name + "=([^&#]*)";
        let regex = new RegExp(regexS);
        let results = regex.exec(url);
        return results == null ? null : results[1];
    }

    useEffect(() => {

        const directAuth = gup('direct_auth', window.location.href)
        setToken(directAuth)

        if (directAuth) {
            const url = window.location.href
        }

    }, [])

    const [activeType, setActiveType] = useState('binary')
    const handleClick = key => {
        if (key === 'binary')
            return handleBinary()


        if (key === 'crypto')
            return handleCrypto()


        if (key === 'fx')
            return handleFx()
    }

    const handleBinary = () => {
        if (!token)
            return alert('Geçersiz Token')

        window?.open('https://binary.apioption.com?direct_auth=' + token, '_self')
    }
    const handleCrypto = () => {
        // if (!allow)
        //     return false;
        if (!token)
            return alert('Geçersiz Token')

        window?.open('https://futures.apioption.com?direct_auth=' + token, '_self')
    }

    const handleFx = () => {
        // if (!allow)
        //     return false;
        if (!token)
            return alert('Geçersiz Token')

        window?.open('https://fx.apioption.com?direct_auth=' + token, '_self')
    }

    const handleHowTo = (type) => {
        if (type === 'fx')
            fxRef?.current?.click()

        if (type === 'crypto')
            cryptoRef?.current?.click()

        if (type === 'binary')
            binaryRef?.current?.click()

        window.scrollTo(0, document.body.scrollHeight);

    }
    return (
        <>
            <div className="section" id="selection">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="section__title">Trade</h1>
                            {/*<p className="section__text">*/}
                            {/*    Tüm oyunlarda gerçek zamanlı piyasa fiyatlarından işlem yapılmaktadır.*/}
                            {/*</p>*/}
                        </div>

                        {
                            stats?.map(item => (
                                <div className="col-12 col-lg-4" key={item.key}>

                                    <p onClick={e => handleHowTo(item.key)}
                                       className="section__text pointer-event">Nasıl Oynanır ?
                                    </p>


                                    <div className={`game ${item.class}`}>
                                        <div className="game__cover">
                                            <h2>{item.title}</h2>
                                            <span>{item.sub}</span>

                                            <ul className="game__icons">
                                                <li>
                                                    <img src="img/coin/bitcoin.svg" alt=""/>
                                                </li>
                                                <li>
                                                    <img src="img/coin/ethereum.svg" alt=""/>
                                                </li>
                                                <li>
                                                    <img src="img/coin/xrp.svg" alt=""/>
                                                </li>
                                                <li>
                                                    <img src="img/coin/sol.svg" alt=""/>
                                                </li>
                                                <li>
                                                    <img src="img/coin/microsoft.svg" alt=""/>
                                                </li>
                                                <li>
                                                    <img src="img/coin/tesla.svg" alt=""/>
                                                </li>
                                                <li>
                                                    <img src="img/coin/apple.svg" alt=""/>
                                                </li>
                                                <li>
                                                    <i className="ti ti-currency-euro"/>
                                                </li>
                                                <li>
                                                    <i className="ti ti-currency-dollar"/>
                                                </li>
                                                <li>
                                                    <i className="ti ti-currency-pound"/>
                                                </li>
                                            </ul>
                                            <div className="game__btns">
                                                <a onClick={e => handleClick(item.key)} className="game__btn">
                                                    Oyna
                                                </a>
                                            </div>
                                        </div>
                                        <p className="section__text">{item?.desc1}</p>

                                        <div className="game__stat">
                                            <span className="game__online">İşlem :{item.count}</span>
                                            <span className="game__volume">
                                              {/*Hacim :<b> {currencyFormat(parseFloat(item.total || 0))}</b>*/}
                                                Hacim :<b>  {Big(item?.total || 0).toFixed(2)}  </b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="section__title">Nasıl Oynanır?</h2>
                            <p className="section__text">
                                Tüm oyunlarda gerçek zamanlı piyasa fiyatlarından işlem yapılmaktadır.
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="how">
                                <div className="how__head">
                                    <ul
                                        className="nav nav-tabs section__navs"
                                        id="section__navs"
                                        role="tablist"
                                    >
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className="active"
                                                data-bs-toggle="tab"
                                                data-bs-target="#tab-01"
                                                type="button"
                                                role="tab"
                                                aria-controls="tab-01"
                                                aria-selected="true"
                                                ref={binaryRef}
                                            >
                                                Binary
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                data-bs-toggle="tab"
                                                data-bs-target="#tab-02"
                                                type="button"
                                                role="tab"
                                                aria-controls="tab-02"
                                                aria-selected="false"
                                                ref={cryptoRef}

                                            >
                                                Crypto Futures
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                data-bs-toggle="tab"
                                                data-bs-target="#tab-03"
                                                type="button"
                                                role="tab"
                                                aria-controls="tab-03"
                                                aria-selected="false"
                                                ref={fxRef}

                                            >
                                                Fx Futures
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content how__content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="tab-01"
                                        role="tabpanel"
                                    >
                                        <div className="row">
                                            <div className="col-12 col-xl-4">
                                                <ul className="how__list">
                                                    <li>
                                                        Sol üst tarafta bulunan Sembol listesinden işlem
                                                        yapacağınız sembolü seçiniz.
                                                    </li>
                                                    <li>
                                                        İşleme girmek istediğiniz [Süre] ve [Miktar]'ı seçiniz.
                                                    </li>
                                                    <li>
                                                        Seçtiğiniz sürede ürün fiyatının düşeceğini düşünüyorsanız
                                                        [Düşer]'e yükseleceğini düşünüyorsanız [Yükselir]'e
                                                        tıklayınız.Tahmininiz doğru sonuçlanırsa kazanç elde
                                                        edersiniz.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-12 col-xl-8">
                                                <div className="row">
                                                    <div className="col-12 col-md-4">
                                                        <img
                                                            className="how__img"
                                                            src={BINARY1}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <img
                                                            className="how__img"
                                                            src={BINARY2}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <img
                                                            className="how__img"
                                                            src={BINARY3}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tab-02" role="tabpanel">
                                        <div className="row">
                                            <div className="col-12 col-xl-4">
                                                <ul className="how__list">
                                                    <li>
                                                        Sembol bölümünden işlem yapmak istediğiniz ürünü seçiniz.
                                                    </li>
                                                    <li>
                                                        İşlem yapacağınız yönü(Al, Sat), [Çarpan] oranını ve
                                                        Bağlayacağınız [Teminat] Miktarını belirleyiniz.
                                                    </li>
                                                    <li>
                                                        İşlem aç'a tıklayarak açık işlemler menüsünde işleminizi
                                                        takip edip; Kazanca geçmeniz durumunda [Kapat] tuşuna
                                                        tıklayarak kazanç elde edebilirsiniz.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-12 col-xl-8">
                                                <div className="row">
                                                    <div className="col-12 col-md-4">
                                                        <img
                                                            className="how__img"
                                                            src={CRYPTO1}

                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <img
                                                            className="how__img"
                                                            src={CRYPTO2}

                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <img
                                                            className="how__img"
                                                            src={CRYPTO3}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tab-03" role="tabpanel">
                                        <div className="row">
                                            <div className="col-12 col-xl-4">
                                                <ul className="how__list">
                                                    <li>
                                                        Sembol bölümünden işlem yapmak istediğiniz ürünü seçiniz.
                                                    </li>
                                                    <li>
                                                        İşlem yapacağınız yönü(Al, Sat), [Çarpan] oranını ve
                                                        Bağlayacağınız [Teminat] Miktarını belirleyiniz.
                                                    </li>
                                                    <li>
                                                        İşlem aç'a tıklayarak açık işlemler menüsünde işleminizi
                                                        takip edip; Kazanca geçmeniz durumunda [Kapat] tuşuna
                                                        tıklayarak kazanç elde edebilirsiniz.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-12 col-xl-8">
                                                <div className="row">
                                                    <div className="col-12 col-md-4">
                                                        <img
                                                            className="how__img"
                                                            src={FX1}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <img
                                                            className="how__img"
                                                            src={FX2}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <img
                                                            className="how__img"
                                                            src={FX3}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    );
}

export default App;
