import React from "react";
import {fetchInstance} from "./fetch-instance";

let CLIENT_API_URL = "https://clientapi.septrader.com/api/";
export let API_PREFIX = '';


class AssociateServices extends React.Component {

    setPrefix = (prefix) => {
        CLIENT_API_URL = "https://clientapi.apioption.com/api/" + prefix // demo/binary
    }


    getStats = (instance) => fetchInstance(CLIENT_API_URL + API_PREFIX + "public/stats", "POST", instance);


}

const associateServices = new AssociateServices();
export default associateServices;
