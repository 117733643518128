import Big from "big.js";
import React from "react";
import './index.css'

const StyledContent = props => {

    function currencyFormat(num) {
        return '' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    try {
        const {price, digits = 2} = props

        // const thePrice = currencyFormat(price)

        const split = price?.toString().split('.')

        // const mega = split[0].split(',')

        // var n = new Intl.NumberFormat().format(split[0]);
        // var res = n.slice(0, 9).concat(".").concat(n.slice(10, 12));

        // const {price, digits = 2} = props
        // const split2 = Big(price || 0).toFixed(parseInt(digits || 0)).split('.')
        return <span className='tt'>
                {/*{Big(split[0] || 0).toFixed(0) || ''}.*/}

            {
                // new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 3})
                //     .format(
                //         Big(split[0] || 0).toNumber(),
                //     )

                split[0]
            } .
            <span className="cool-d">
                    {Big(split[1] || 0).toFixed(2) || ''}
                </span>
        </span>

        // return (
        //     <span className='tt'>
        //         {mega[0] || ''}.
        //         <span className="cool-d">
        //             {mega[1] || ''}.
        //         </span>
        //     <span className="cool-dd">
        //         {split[1]}
        //         {/*{Big(split[1])?.toFixed(parseInt(digits || 0))}*/}
        //     </span>
        // </span>
        // )
    } catch (e) {
        console.error(e)
        // const {price, digits = 2} = props
        // const split2 = Big(price || 0).toFixed(parseInt(digits || 0)).split('.')
        // return <span className='tt'>
        //         {split2[0] || ''}.
        //         <span className="cool-d">
        //             {split2[1] || ''}
        //         </span>
        // </span>
    }


}

export default StyledContent
